<template>
  <v-row class="pt-0 mt-0">
    <SidePage />
    <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="pa-5 pa-sm-10">
          <v-row justify="center">
            <v-col cols="12" md="12" lg="12" xl="12">
              <img src="@/assets/images/logos/logo-comillas.png" width="40" />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Set a new Password!</h2>
              
              <v-form 
                ref="form" 
                v-model="valid" 
                lazy-validation
              >
                <v-text-field
                  v-model.trim="form.password"                  
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  class="mt-4"
                  @click:append="show1 = !show1"
                  :rules="editedItemRules.passwordRules"
                  label="Password"
                  name="Password"
                  counter
                  outlined
                  required
                ></v-text-field>

                <v-text-field
                  v-model="form.confirmPassword"
                  @keydown.enter="btnClick"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :rules="editedItemRules.confirmPasswordRules"
                  label="Confirm password"
                  name="Password"
                  counter
                  outlined
                  required
                ></v-text-field>  
                
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  class="mr-4 my-2"
                  block
                  submit
                  @click="btnClick"
                >
                  <span>Set Password</span>
                  &nbsp;
                </v-btn>
              </v-form>

              <div class="d-block align-center mb-4 mt-3 mb-sm-0">                  
                <div class="ml-auto d-flex justify-space-between">
                  <div>
                    <small>Go back to</small>
                    <router-link :to="{ name: 'SignIn' }" class="ml-1" >Sign in</router-link>
                  </div>
                </div>
              </div>
              
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SidePage from './SidePage.vue'
import axios from 'axios';

export default {
  name: "SetNewPassword",
  components:{
    SidePage
  },
  data: () => ({
    show1: false,
    show2: false,
    valid: true,
    loader: null,
    loading: false,
    form: {
      password: '',
      confirmPassword: '',
      resetCode: ''
    }, 
  }),
  mounted() {
    this.form.resetCode = this.$route.params.code;
  },
  computed: {
    editedItemRules(){
      return { 
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length > 4) || "Password must be more than 4 characters long",
          v => (v && v.length <= 26) || "Password must be less than 27 characters long"
        ],
        confirmPasswordRules: [
          (v) => !!v || "Confirm password is required",
          (v) => v === this.form.password || "The password confirmation does not match",
        ],
      }
    }
  },
  methods: {
    validate () {
      const result = this.$refs.form.validate()
      return result;
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async submit() {

      try {
        this.form.resetCode = this.$route.params.code;
        const {data} = await axios.post('api/users/set-password', this.form);    
        
        this.valid = true;
        this.loader = null
        this.resetValidation()
        this.reset()
        this.$alertify.success(data)
      } catch (message) {
        this.$alertify.error(message)
        this.valid = true;
        this.loader = null
      }
    },
    btnClick(){
      this.loader = 'loading'
      const l = this.loader
      this[l] = !this[l]
      this.valid = false;

      setTimeout(() => {
        if(this.validate()){                
          this.submit()              
        }           
        (this[l] = false)
          
      }, 2000)
    },
  }
};
</script>
