<template>
    <v-container fluid class="pb-0">
        <Loader loaderType="spinner-message" v-if="loading == true"/>
        <div class="my-2 ml-5">
            <router-link :to="{ name: loggedin ? 'Analytical': 'SignIn' }">
                <v-btn
                    color="primary"
                    fab                
                    dark
                >      
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
            </router-link>
        </div>

        <v-row class="col-12 col-md-12 col-lg-12 px-0 mx-0 my-0 py-0">
            <v-col cols="12" md="8" xl="12" class="pb-0 my-0 cursor" style="height: 68vh;">
                <v-card
                    class="mx-auto px-3 py-2 overflow-y-visible"
                    width="auto"
                    height="100%"
                    color="#e7e0e0e0"
                    style="overflow-y: auto"
                > 
                    <v-row dense class="height">
                        <vue-dropzone 
                            ref="myVueDropzone" 
                            id="dropzone" 
                            :options="dropzoneOptions"     
                            @vdropzone-complete="fileAdded"
                            :useCustomSlot=true                           
                        >
                            <div class="dropzone-custom-content height d-flex align-center">
                                <v-col>                                
                                    <h3 class="dropzone-custom-title">Click to select a file!</h3>
                                    <div class="subtitle">file type PDF, CSV, DOCX or TXT </div>
                                    <v-btn
                                        class="ma-2"
                                        color="secondary"
                                        dark
                                    >
                                        <v-icon dark>
                                            mdi-cloud-upload
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </div>
                        </vue-dropzone>
                    </v-row>
                </v-card>
            </v-col>

            <v-col
                cols="12"
                md="4"                       
            >                
                <v-row 
                    class="col col-12 px-0 py-0 mx-1 my-0 d-flex align-center height"
                >                     
                    <v-btn   
                        :disabled="valid"                      
                        @click.stop="openDialog" 
                        width="100%" 
                        style="margin-top: 20px; color: white" 
                        color="blue"
                        large
                    >
                        Upload
                    </v-btn>
                    
                </v-row>
            </v-col>           
        </v-row>

        <v-dialog 
            persistent
            v-model="dialog" 
            max-width="750px"
        > 
            <v-card>
                <v-card-title primary-title>
                    <span class="headline">Document information</span>
                </v-card-title>
                
                <v-card-text class="mb-0">
                    <v-container>
                        <v-form
                            ref="form2"
                            v-model="valid2"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea 
                                        auto-grow
                                        rows="1" 
                                        name="input-7-4" 
                                        label="Document description"
                                        v-model="file_description" 
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions
                    class="mx-2 mb-0"
                >
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="uploadFile">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from "vuex";

import Loader from '@/components/Loader'

export default {
    name: 'file-upload',
    components: {
        Loader
    },
    data: () => ({
        dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailWidth: 250,
            maxFilesize: 15,
            addRemoveLinks: true,
            maxFiles: 1,
            acceptedFiles: '.pdf,.csv,.docx,.txt',
        },
        checkValid: null,
        valid: true,
        valid2: true,
        file_description: '',
        dialog: false,
        companyCode: ''
    }),
    computed: {
        ...mapState({
            loading: state => state.auth.loading,
        }),
        ...mapGetters(['currentUser']),
        loggedin() {
            if(this.currentUser?.user_id) return true
            return false
        }
    },
    mounted(){
        this.companyCode = this.$route.params.code;        
    },
    methods: {
        fileAdded(){
            this.checkValid = this.checkImportType(this.$refs.myVueDropzone.getAcceptedFiles()[0])
            if(this.checkValid == true) this.valid = false
        },         
        async uploadFile(){
            try {
                if(!this.validate2()) return;

                const inputFile = this.$refs.myVueDropzone.getAcceptedFiles()
                const result = await this.arrayOfFiles(inputFile); 

                const files = {
                    files: result,
                    company_code: this.companyCode,
                    file_description: this.file_description
                }             
                
                await axios.post('api/files/upload-external-file', files)

                this.close()
                this.reset2()
                this.$alertify.success("File uploaded succesfully!")

                this.$refs.myVueDropzone.removeAllFiles()
            } catch (error) {                
                this.$alertify.error(error)
            }
        },        
        openDialog(){
            if(this.$refs.myVueDropzone.getAcceptedFiles() <= 0) return this.valid = true           
            this.dialog = true
        },
        close(){
            this.reset2()
            this.resetValidation2()
            this.dialog = false
            this.companyCode = "";
        },
        checkImportType(fileArr){ 
            if(fileArr == undefined) return true   
            if(fileArr == null) return true;   
            if(fileArr.length <= 0) return true;
            
            if(fileArr.extension) return true;  
                //--------------------
            const index = fileArr.name.split("").reverse().indexOf(".");
            const fileType = fileArr.name.split("").reverse().splice(0, index).reverse().join("").toLowerCase();        
            const same = (element) => element == fileType;
            const allowed = ['txt', 'csv', 'pdf', 'docx'];
            return allowed.some(same)
        },
        requireAttachment(v){ 
            if(v == undefined) return false
            if(v == undefined && this.files.length <= 0) return false; 
            if(this.files.length <= 0) return false     
            return true        
        },
        validate2() {  
            if(!this.companyCode || this.companyCode.length < 16) this.companyCode = this.$route.params.code;   

            if(!this.companyCode || this.companyCode.length < 16) {
                this.$alertify.error('Please add the company code to the URL')
                return false
            }     
            return this.$refs.form2.validate()        
        },
        reset2() { 
            this.$refs.form2.reset() 
        },
        resetValidation2() {
            this.$refs.form2.resetValidation()            
        },
        toBase64(file){
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async arrayOfFiles(fileSelect){ 
            const arr = []        
            for (let i = 0; i < fileSelect.length; i++) {            
                let name = fileSelect[i].name.split('.').reverse()
                name.splice(0, 1)
                name = name.reverse().join('.')
                let extension = fileSelect[i].name.split('.').reverse()[0]
                let result = await this.toBase64(fileSelect[i])
                let size = fileSelect[i].size

                arr.push({name: name, extension: extension, base64: result, size: size})
            }
            return arr;
        },        
    }
}
</script>

<style scoped>

.cursor {
  cursor: pointer;
}

.height {
    height: 100%;    
}

.border-radius > *:first-child:not(.v-btn):not(.v-chip) {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#dropzone.dz-drag-hover {
    border: 5px dashed #89c90a;
}

.hoverIcon:hover {
    background-color: #c1b8b8e0;
}

.selected {
    background-color: #c1b8b8e0;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #000000;
}

.subtitle {
  color: #314b5f;
}

#dropzone {
    width: 100%;
    border-radius: 15px;
    padding: 10px!important;
    display: flex;
    justify-content: center;
}

.dz-error-message >>> {
    top: 50%!important;
}
.dropzone .dz-preview >>> {
    width: 100%!important;
}

.dropzone .dz-preview .dz-details >>> {
    z-index: 1!important;
}

@media  (min-width: 1265px) {
    /* .filter-menu {
        margin-top: 40px;
        margin-right: 20px;
    } */
}
.filter-menu {
  position: absolute;
  background-color: white!important;
  
  z-index: 1;
}

.list-item:hover {
  background-color: rgba(0, 0, 0, 0.137);
}

</style>