<template>
  <div class="d-flex justify-center align-center container-custom" grid-list-xs>
    
  
    <div class="page-loader flex-column" v-if="loaderType === 'spinner-message'">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
      <span class="text-muted fs-6 fw-bold mt-5">Loading...</span>
    </div>
  
    <!-- <div class="page-loader flex-column" v-else-if="loaderType === 'spinner-logo'">
      <img alt="Logo" class="max-h-75px" :src="logo" />
      <div class="d-flex align-items-center mt-5">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>    
      </div> -->
      <!-- <span class="text-muted fs-6 fw-bold ms-5">Loading...</span> -->
    <!-- </div> -->
  
    <div class="page-loader" v-else>    
      <div class="loading-container">
        <div class="loading-text">
          <span class="primary--text">L</span>
          <span class="primary--text">O</span>
          <span class="primary--text">A</span>
          <span class="primary--text">D</span>
          <span class="primary--text">I</span>
          <span class="primary--text">N</span>
          <span class="primary--text">G</span>
        </div>
      </div> 
      
    </div>
  </div>
</template>
  
  <script>
   // Utilities
//   import { get } from 'vuex-pathify'
  
  export default {
    name: "Loader",
    props: {
      loaderType: String
    },
    data: () => ({
    }),
    computed: {
    //   ...get('layout', [
    //     'logo',
    //   ]),
    }
  };
  </script>
  
  <style lang="scss">
  .container-custom {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.534);
  }

  // .loading-container {
  //   width: 100%;
  //   max-width: 50vw;
  //   height: 100%;
  //   text-align: center;
  //   color: rgb(0, 0, 0);
  //   position: relative;
  //   margin: 0 32px;
  // }

  // .loading-container:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 3px;
  //   background-color: rgb(0, 0, 0);
  //   bottom: 0;
  //   left: 0;
  //   border-radius: 10px;
  //   animation: movingLine 2.4s infinite ease-in-out;
  // }

  // .loading-text {
  //   font-size: 5vw;
  //   line-height: 64px;
  //   letter-spacing: 10px;
  //   margin-bottom: 32px;
  //   display: flex;
  //   justify-content: space-evenly;    
  // }

  // .loading-text span {
  //   color: black;
  //   animation: moveLetters 2.4s infinite ease-in-out;
  //   transform: translatex(0);
  //   position: relative;
  //   display: inline-block;
  //   opacity: 0;
  //   text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3); 
  // }
  </style>